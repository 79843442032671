<template>
  <nut-navbar @on-click-back="back()" title="我的订单"/>
  <div class="whitebox" style="overflow: scroll">
    <ul v-if="orderData.length > 0">
      <li v-for="( o , i) in orderData" :key="i" style="width: 100%;overflow: hidden;">
        <nut-swipe>
          <nut-cell round-radius="0">
            <div>
              <img :src="'https://www.sview3d.com'+o.productImg" :style="{height: '130px',width:'140px'}"/>
              <p><em style="font-size: 9px">订单号：{{ o.orderno }}</em></p>
            </div>
            <div class="pro-info">
              <h3 class="pro-name">{{ o.productName }}</h3>
              <p>{{ o.fashionName }}</p>
              <p>购买数量：{{ o.number }}</p>
              <p>支付方式：<em style="font-size: 9px">{{ o.payType === '0' ? '微信' : '支付宝' }}</em></p>
              <p class="price">
                <em style="color:#000000">实际付款 ¥{{ o.totalPrice }}</em>
              </p>
              <p><em style="font-size:9px">支付时间：{{ o.payTime }}</em></p>
            </div>
          </nut-cell>
          <template #right>
            <nut-button :disabled="o.isInvoice==='2'" shape="square" style="height: 100%"
                        :type="o.isInvoice === '3' ?'danger':'info'"
                        @click="invoicePopup(o)">
              {{
                o.isInvoice === '3' ? '审核不通过' : (o.isInvoice === '1' ? (o.invoiceState === '6' ? (fromtype == '0' || fromtype == '1' ? '发票已开具，请至邮箱查收' : '下载发票') : '已开票') : (o.isInvoice === '2' ? '审核中' : '开发票'))
              }}
            </nut-button>
          </template>
        </nut-swipe>
      </li>
    </ul>
    <empty v-else>暂无订单</empty>
  </div>
  <!-- 开发票弹出层 -->
  <nut-popup
      position="bottom"
      :style="{ height: '100%' }"
      v-model:visible="invoiceShow"
  >
    <nut-navbar
        @on-click-back="invoiceBack()"
        title="企业开票"
    ></nut-navbar>
    <div class="content">
      <form :model="invoiceForm">
        <nut-row>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入 企业名称"
                :require-show="true"
                label="企业名称"
                v-model="invoiceForm.invoiceCode"
            />
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入企业税号"
                :require-show="true"
                label="企业税号"
                v-model="invoiceForm.taxNo"
            />
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                :require-show="true"
                placeholder="请输入公司详细地址"
                label="企业地址"
                v-model="invoiceForm.address"
            />
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                :require-show="true"
                placeholder="请输入企业电话号码"
                label="企业电话"
                v-model="invoiceForm.telePhone"
            />
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                :require-show="true"
                placeholder="请输入开户银行"
                label="开户银行"
                v-model="invoiceForm.depositBank"
            />
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                :require-show="true"
                placeholder="请输入开户账号"
                label="开户账号"
                v-model="invoiceForm.bankAccount"
            />
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                :require-show="true"
                placeholder="请输入邮箱"
                label="邮箱"
                v-model="invoiceForm.mail"
            />
          </nut-col>
          <nut-col :span="24">
            <p :style="{display: errorDisplay }">
              <em class="text-red" style="font-size: 10px">审核失败原因：</em>
              <em style="font-size: 10px;color:#808080">
                {{ invoiceForm.errorMsg }}
              </em>
            </p>
            <div class="blank10"></div>
            <p><em class="text-red" style="font-size: 10px">*注：</em>
              <em style="font-size: 10px;color:#808080">
                本订单开具增值税电子发票，信息填写后请留下您接收发票的邮箱，如有问题可联系客服人员。
              </em>
            </p>
          </nut-col>
        </nut-row>
        <nut-row>
          <nut-col :span="24" class="form-item lg-btn">
            <nut-button
                plain
                type="info"
                class="blue-btn"
                @click="invoicing"
                :loading="loadingConfirm"
            >
              {{ confirmContent }}
            </nut-button>
          </nut-col>
        </nut-row>
      </form>
    </div>
  </nut-popup>
</template>
<script>
import {useRoute, useRouter} from 'vue-router'
import {onMounted, reactive, toRefs, ref, refs} from "vue";
import {Notify} from '@nutui/nutui'
import axios from "axios";
import Empty from '@/components/Empty'
import{ getCookie, setCookie } from '@/utils/cookie'
export default {
  name: 'order',
  components: {
    Empty
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    let Base64 = require('js-base64').Base64;
    let innerId = Base64.decode(route.query.innerId);
    const data = reactive({
      //订单数据
      orderData: []
    })
    //发票信息
    const invoiceForm = reactive({
      invoiceCode: '',
      taxNo: '',
      telePhone: '',
      address: '',
      depositBank: '',
      bankAccount: '',
      mail: '',
      errorMsg: ''
    })
    // 判断是否是来自app 0 安卓 1 苹果
    if (route.query.fromtype) {
      setCookie('fromtype', route.query.fromtype)
    }
    const fromtype = ref()
    if (getCookie('fromtype')) {
      fromtype.value = getCookie('fromtype')
    }
    console.log('fromtype: ' + fromtype.value)

    if (route.query.onlineid) {
      setCookie('onlineid', route.query.onlineid)
    }
    const onlineid = ref()
    if (getCookie('onlineid')) {
      onlineid.value = getCookie('onlineid')
    }
    console.log('onlineid: ' + onlineid.value)
    //选择订单号
    const orderNo = ref("");
    let invoiceShow = ref(false);
    let confirmContent = ref('提交');
    let loadingConfirm = ref(false);
    let errorDisplay = ref('none');
    onMounted(() => {
      onReturn();
      getOrderList();
    })
    //查询订单列表
    const getOrderList = () => {
      let url = 'https://work.sview3d.com/getOrderList';
      const params = new URLSearchParams();
      params.append("userId", innerId);
      axios.post(url, params).then(function (result) {
        if (result.data.success) {
          const orderList = result.data.orderList;
          if (orderList.length > 0) {
            for (const order of orderList) {
              data.orderData.push(order.mapObject);
            }
          }
          console.log("订单列表数据", data.orderData)
        } else {
          console.log('查询数据为空');
        }
      }).catch(function (error) {
        console.log(error);
      })
    }

    const invoicePopup = (o) => {
      if ('' === o.isInvoice || '0' === o.isInvoice || '3' === o.isInvoice) {
        orderNo.value = o.orderno;
        invoiceShow.value = true;
        errorDisplay.value = 'none';
        invoiceForm.invoiceCode = o.enterprise;
        invoiceForm.taxNo = o.dutyParagraph;
        invoiceForm.telePhone = o.phone;
        invoiceForm.address = o.address;
        invoiceForm.depositBank = o.bank;
        invoiceForm.bankAccount = o.account;
        invoiceForm.mail = o.description;
        invoiceForm.errorMsg = o.errorMsg;
        if ('3' === o.isInvoice) {
          errorDisplay.value = '';
        }
      }
      if ('1' === o.isInvoice && '6' === o.invoiceState) {
        if (fromtype.value != 0 && fromtype.value != 1) {
          console.log("o.pdfPath",o.pdfPath);
          window.open("https://service.sview3d.com"+o.pdfPath.replace(/\\/g, '/'));
        }
      }
    }
    const invoicing = () => {
      if (checkData()) {
        loadingConfirm.value = true;
        confirmContent.value = '提交中';
        let url = 'https://work.sview3d.com/makeOutInvoice';
        const params = new URLSearchParams();
        params.append("userId", innerId)
        //params.append("userId", '3fdcf73d-7bdf-4ea2-ba22-1eb0f543a1fa')
        params.append("orderNo", orderNo.value);
        //企业发票默认为0
        params.append("invoiceType", "0");
        params.append("invoiceCode", invoiceForm.invoiceCode);
        params.append("taxNo", invoiceForm.taxNo);
        params.append("address", invoiceForm.address);
        params.append("telephone", invoiceForm.telePhone);
        params.append("depositBank", invoiceForm.depositBank);
        params.append("bankAccount", invoiceForm.bankAccount);
        params.append("email", invoiceForm.mail);
        params.append("identityNumber", "");
        axios.post(url, params).then(function (result) {
          if (result.data.success) {
            Notify.primary(result.data.msg);
            invoiceShow.value = false;
            data.orderData = [];
            getOrderList();
          } else {
            Notify.danger(result.data.msg);
          }
          loadingConfirm.value = false;
          confirmContent.value = '提交';
        }).catch(function (error) {
          console.log(error);
          loadingConfirm.value = false;
          confirmContent.value = '提交';
        })
      }
    }

    const checkData = () => {
      if ('' === invoiceForm.invoiceCode) {
        Notify.warn("请输入企业名称");
        return false;
      }
      if ('' === invoiceForm.taxNo) {
        Notify.warn("请输入企业税号");
        return false;
      }
      if ('' === invoiceForm.address) {
        Notify.warn("请输入公司详细地址");
        return false;
      }
      if ('' === invoiceForm.telePhone) {
        Notify.warn("请输入企业电话号码");
        return false;
      }
      if ('' === invoiceForm.depositBank) {
        Notify.warn("请输入开户银行");
        return false;
      }
      if ('' === invoiceForm.bankAccount) {
        Notify.warn("请输入开户账号");
        return false;
      }
      if ('' === invoiceForm.mail) {
        Notify.warn("请输入邮箱");
        return false;
      }
      return true;
    }


    const invoiceBack = () => {
      invoiceShow.value = false;
    }

    const back = () => {
      if (fromtype.value != 0 && fromtype.value != 1 ) {
        router.push({name: 'member', params: {tabMenu: '3'}, query: {innerId: route.query.innerId}})
      } else {
        router.replace({name: 'member', params: {tabMenu: '3'}, query: {innerId: route.query.innerId}})
      }
    }

    // 点击回退事件
    const onReturn = () => {
      // 向历史记录中插入了tia
     console.log(history) 
      
      // history.pushState(null, null, document.URL);
      // window.addEventListener('popstate', this.goBack, false);
      // window.addEventListener('popstate', function () {
      //   if (fromtype.value == 0 || fromtype.value == 1) {
      //     // window.location.replace(`https://service.sview3d.com/service/user/toVipIntroduceTest?onlineid=${onlineid.value}&fromtype=1&version=9.0.0`)
      //     window.location.href = `https://service.sview3d.com/service/user/toVipIntroduceTest?onlineid=${onlineid.value}&fromtype=1&version=9.0.0`
      //     // window.location.href = `http://10.0.44.136/service/user/toVipIntroduceTest?onlineid=${onlineid.value}&fromtype=1&version=9.0.0`
      //   }
      // });
    };
    return {
      back,
      orderNo,
      ...toRefs(data),
      invoiceForm,
      invoiceShow,
      invoicePopup,
      invoicing,
      invoiceBack,
      confirmContent,
      loadingConfirm,
      errorDisplay,
      // onReturn,
      fromtype
    }
  }
}
</script>