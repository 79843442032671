<template>
  <div class="empty">
    <img class="img" src="@/assets/img/nodata.png" />
    <p class="no-data"><slot></slot> </p>
  </div>
</template>
<style scoped>
  .empty {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .img {
    width: 50%;
    margin: 40px auto;
  }
  .no-data {
    color: #999;
  }
</style>